import React, { useEffect, useRef, useState,Suspense } from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import { ProductOverlayConstants } from '../../adapters/helpers/Constants';
import {getBuyNowLiteRetailerList} from '../../adapters/model/service/api/buyNowLite';
import Block from '../../adapters/helpers/Block';
import { loadScript } from '../../adapters/helpers/Utils';
import { toggleScroll } from '../../adapters/helpers/Utils';
const Icon = dynamic(() => import('../Icon/Icon'));
import Loader from '../../adapters/helpers/Loader';

export default function ProductOverlay(props) {
    const displayBuyNowLite = (process.env.ACTIVATE_BUY_NOW_LITE || false) === 'TRUE';

    const { customEvent, onClickCallback, states, extraAttributes } = props;
    const block = new Block(props);
    const [isOverlayOpen, setIsOverlayOpen] = useState(states.isOverlayOpen);
    const [lastButton, setLastButton] = useState(null);
    const [lastScroll, setLastScroll] = useState(0);
    const [productSku, setProductSku] = useState('');
    const KEYCODE_ESC = 27;
    const anchorId = block.getAnchorId();
    const productOverlayRef = useRef();
    const closeOverlayLabel = block.getFieldValue(ProductOverlayConstants.closeOverlayLabel)?.fields?.text;
    const buyNowLiteErrorLabel = block.getFieldValue(ProductOverlayConstants.buyNowLiteErrorLabel)?.fields?.text;
    let classNames = block.getFieldValue(ProductOverlayConstants.classNames) ? block.getFieldValue(ProductOverlayConstants.classNames) : ProductOverlayConstants.empty;
    classNames += `${anchorId}`;

    useEffect(() => {
        setLastScroll(window.pageYOffset);
        const productVariants = extraAttributes?.entity?.productOverview?.fields?.productVariants;
        const series = extraAttributes?.entity?.series;
        let initialSku = '';
        if (extraAttributes) {
            if (productVariants?.length > 0 && productVariants[0]?.fields?.sku) {

                initialSku = extraAttributes?.entity?.productOverview?.fields?.productVariants[0]?.fields?.sku;

            } else if (series?.length > 0 && series[0]?.fields?.featuredProduct?.fields?.productVariants?.length > 0 && series[0]?.fields?.featuredProduct?.fields?.productVariants[0]?.fields?.sku) {

                initialSku = extraAttributes?.entity?.series[0]?.fields?.featuredProduct?.fields?.productVariants[0]?.fields?.sku;

            }
        }
        setProductSku(initialSku);
    }, []);

    const onGetBuyNowLiteRetailerListSuccess = (data) => {
        const buyNowLiteContainer = document.querySelector('.buy-now-lite-container');
        for (const item of data) {
            let linkNode = document.createElement('a');
            linkNode.href = item.BuyNowUrl;
            linkNode.target = '_blank';
            linkNode.rel = 'noopener noreferrer';
            linkNode.className = 'buy-now-lite-link';

            let imgNode = document.createElement('img');
            imgNode.src = 'data:image/png;base64,' + item.Retailerlogo;
            imgNode.className = 'buy-now-lite-img';
            imgNode.alt = '';
            linkNode.appendChild(imgNode);

            buyNowLiteContainer.appendChild(linkNode);
        }

        openBuyNowLiteModal();
    };

    const onGetBuyNowLiteRetailerListFailure = () => {
        // close modal and remove buy now lite retailer images from modal.
        const buyNowLiteContainer = document.querySelector('.buy-now-lite-container');
        let paragraphNode = document.createElement('p');
        paragraphNode.className = 'buy-now-lite-error-message';
        paragraphNode.textContent = buyNowLiteErrorLabel;

        buyNowLiteContainer.appendChild(paragraphNode);

        openBuyNowLiteModal();
    };

    useEffect(() => {
        if (!customEvent?.event) {
            return;
        }
        if (customEvent.event instanceof HTMLElement || customEvent.event instanceof Node) {
            if (displayBuyNowLite) {
                customEvent.event.disabled = true;
            }
            const sku = customEvent?.event?.dataset?.sku ? customEvent.event.dataset.sku : '';
            let resetCountryCode = customEvent?.event?.dataset?.resetCountryCode ? customEvent.event.dataset.resetCountryCode : false;

            // ACTIVATE_BUY_NOW_LITE is true for japan
            if (process.env.ACTIVATE_BUY_NOW_LITE) {
                if(sku !== '') {
                    // Ajax call to get retailer data.
                    getBuyNowLiteRetailerList(sku, onGetBuyNowLiteRetailerListSuccess, onGetBuyNowLiteRetailerListFailure);
                } else {
                    // If no sku, close modal and remove buy now lite retailer images from modal.
                    const buyNowLiteContainer = document.querySelector('.buy-now-lite-container');
                    buyNowLiteContainer.innerHTML = '';
                    closeBuyNowLiteModal();
                }
            } else { // Price spider buy now case.
                if (sku !== '' && window.PriceSpider === undefined) {
                    // script priceSpider with callback
                    loadScript(ProductOverlayConstants.priceSpiderSource, (error) => {
                        if (error) {
                            console.log(ProductOverlayConstants.priceSpiderConsoleLogMessageError);
                        } else {
                            // script loaded successfully
                            setProductSku(sku);
                            setLastButton(customEvent.event);
                            setIsOverlayOpen(true);
                        }
                    });
                } else if (sku !== '') {
                    // script already loaded
                    setProductSku(sku);
                    setLastButton(customEvent.event);
                    setIsOverlayOpen(true);
                } else {
                    setProductSku('');
                    setIsOverlayOpen(false);
                }

                if (resetCountryCode && window?.PriceSpider) {
                    window.PriceSpider.reset();
                }
            }
        }
    }, [customEvent]);

    useEffect(() => {
        if (productSku !== '' && window?.PriceSpider) {
            window.PriceSpider.rebind();
        }
    }, [productSku]);

    useEffect(() => {
        if (productOverlayRef?.current) {
            toggleOverlay();
            productOverlayRef.current.addEventListener(ProductOverlayConstants.keydown, trapFocusInOverlay);
            return () => {
                productOverlayRef.current?.removeEventListener(ProductOverlayConstants.keydown, trapFocusInOverlay)
            }
        }
    },[isOverlayOpen]);

    useEffect(() => {
        const handleScroll = throttle(() => {
            if (!document.body.classList.contains(ProductOverlayConstants.noScroll)) {
                setLastScroll(window.pageYOffset);
            }
        }, 100);
        window.addEventListener(ProductOverlayConstants.scroll, handleScroll);
        return () => {
            window?.removeEventListener(ProductOverlayConstants.scroll, handleScroll);
        }
    },[lastScroll]);

    const closeBuyNowLiteModal = () => {
        setProductSku('');
        setIsOverlayOpen(false);
    }

    const openBuyNowLiteModal = () => {
        if (displayBuyNowLite) {
            customEvent.event.disabled = false;
        }
        setLastButton(customEvent.event);
        setIsOverlayOpen(true);
    }

    const toggleOverlay = () => {
        const productOverlayElem = productOverlayRef.current;
        let ele = document.getElementById('ob-product-subnav-scroll');

        if (isOverlayOpen) {
            if(ele) {
                ele.setAttribute('style', 'z-index:99 !important');
            }
            productOverlayElem.classList.add(ProductOverlayConstants.isActive);
            productOverlayElem.focus();
        } else {
            if(ele) {
                ele.setAttribute('style', 'z-index:999 !important');
            }
            productOverlayElem.classList.remove(ProductOverlayConstants.isActive);
            document.body.style.top = null;
            document.body.style.height = null;
            if (lastButton) {
                lastButton.focus();
            }
        }
        toggleScroll(isOverlayOpen, lastScroll);
    };

    const trapFocusInOverlay = event => {
        if (!isOverlayOpen) {
            return;
        }
        const element = event.currentTarget;
        const focusableElements = element.querySelectorAll(ProductOverlayConstants.querySelectorAll);
        const firstFocusableElement = focusableElements[0];
        const lastFocusableElement = focusableElements[focusableElements.length - 1];
        const KEYCODE_TAB = 9;

        const isTabPressed = (event.key === ProductOverlayConstants.Tab || event.keyCode === KEYCODE_TAB);

        handleEscapeKeyPress(event);

        if (!isTabPressed) {
            return;
        }

        if ( event.shiftKey ) /* shift + tab */ {
            if (document.activeElement === firstFocusableElement) {
                lastFocusableElement.focus();
                event.preventDefault();
            }
        } else /* tab */ {
            if (document.activeElement === lastFocusableElement) {
                firstFocusableElement.focus();
                event.preventDefault();
            }
        }
    };

    const handleBackgroundClick = event => {
        event.stopPropagation();
        if (event.target ===  document.querySelector('.ob-product-overlay')) {
            setIsOverlayOpen(false);

            if(displayBuyNowLite) {
                // When clicking in background remove buy now lite retailer.
                const buyNowLiteContainer = document.querySelector('.buy-now-lite-container');
                buyNowLiteContainer.innerHTML = '';
            }
        }
    };

    const handleEscapeKeyPress = event => {
        if (event.key === ProductOverlayConstants.Escape || event.keyCode === KEYCODE_ESC) {
            setIsOverlayOpen(false);
        }
    }

    return (
        <Suspense fallback={<Loader />}>
        <div role='presentation' onClick={handleBackgroundClick} onKeyDown={handleEscapeKeyPress} ref={productOverlayRef} className={`ob-product-overlay ${classNames}`}>
            <div className={`ob-product-overlay__wrapper ${displayBuyNowLite ? 'ob-product-overlay__wrapper--buy-now-lite' : ''}`}>
                {
                    isOverlayOpen &&
                    <button aria-label={closeOverlayLabel} className={'ob-product-overlay__close-btn'} onClick={event => onClickCallback(event)}>
                        <Icon name={ProductOverlayConstants.close} size={2} color="#002d72"/>
                    </button>
                }
                <div id="ob-product-overlay__content">
                    {displayBuyNowLite ?
                        <>
                            <div className="buy-now-lite-container" >

                            </div>
                        </>
                        :
                        <>
                            <div className="ps-widget" ps-sku={productSku} data-action-detail={productSku}></div>
                        </>
                    }


                </div>
            </div>
        </div>
        </Suspense>
    )
}

ProductOverlay.defaultProps = {
    states: {isOverlayOpen: false}
};

ProductOverlay.propTypes = {
    customEvent: PropTypes.object,
    onClickCallback: PropTypes.func,
    states: PropTypes.object,
    children: PropTypes.node,
    extraAttributes: PropTypes.object,
};
